<template>
  <div class="w-full h-full pt-5 pb-2 flex flex-col justify-between">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-3">Compensation</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <div class="w-full flex flex-col justify-between">
        <template v-if="hasModuleAccess">
          <div class="flex flex-col justify-center items-center gap-5 px-2 pt-10">
            <div class="flex self-start">
              <h3 class="font-bold text-lg leading-5 text-darkPurple">
                Revision History
              </h3>
            </div>
            <template v-if="isLoading">
              <div class="flex justify-center align-center w-full my-28 py-3">
                <loader :loader-image="false" />
              </div>
            </template>
            <template v-else>
              <template v-if="historys.length">
                <Table
                  :headers="headers"
                  :items="historys"
                  :has-number="true"
                  :loading="isFetching"
                  :pagination-list="pagination"
                  page-sync
                  class="w-full"
                  @page="onGetQuery({ page: $event })"
                  @itemsPerPage="onGetQuery({ perPage: $event })"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.paygrade" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">{{ item.data.paygrade.payGradeId }}</span>
                    </div>
                    <div v-if="item.currentRangeMinimum">
                      <span class="text-darkPurple font-normal text-sm">
                        {{ convertToCurrency(item.data.currentRangeMinimum) }}
                      </span>
                    </div>
                    <div v-if="item.currentRangeMidPoint">
                      <span class="text-darkPurple font-normal text-sm">
                        {{ convertToCurrency(item.data.currentRangeMidPoint) }}
                      </span>
                    </div>
                    <div v-if="item.currentRangeMaximum">
                      <span class="text-darkPurple font-normal text-sm">
                        {{ convertToCurrency(item.data.currentRangeMaximum) }}
                      </span>
                    </div>
                    <div v-if="item.updatedAt">
                      <span class="text-darkPurple font-normal text-sm">
                        {{ $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy") }}
                      </span>
                    </div>
                    <div v-if="item.revisedBy" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ item.data.revisedBy.fname }} {{ item.data.revisedBy.lname }}
                      </span>
                    </div>
                    <div v-if="item.id">
                      <div class="cursor-pointer" @click="$refs.history_details.toggle(item.data)">
                        <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                      </div>
                    </div>
                  </template>
                </Table>
              </template>
              <template v-else>
                <div class="w-full flex flex-col justify-center items-center gap-5 px-10 pb-20">
                  <Icon size="" class-name="w-80 h-80" icon-name="payinsight_Illustration" />
                  <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                    A list of pay insights for your organisaton will be shown here. Once added you can filter and perform necessary actions
                  </p>
                </div>
              </template>
            </template>
          </div>
        </template>
        <div v-else>
          <ErrorComponent />
        </div>
      </div>
      <ViewHistoryDetails ref="history_details" />
    </template>
  </div>
</template>

<script>
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Icon from "@/components/Icon";
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    name: 'ViewEmployeePayInsight',
    components: {
      Breadcrumb,
      BackButton,
      Table,
      Icon,
      ErrorComponent,
      ViewHistoryDetails: () => import("../Modals/ViewHistoryDetails")
    },
    data(){
      return {
        isLoading: true,
        isFetching: true,
        hasModuleAccess: false,
        queryParams: {},
        historys: [],
        historyId: '',
        pagination: { page: 1, lastPage: 1, total: 1, from: 1, to: 1 },
        breadcrumbs: [
          { disabled: false, text: "Compensation", href: "compensation", id: "Compensation" },
          { disabled: false, text: "Pay Insight", href: "CompensationPayInsight", id: "CompensationPayInsight" },
          { disabled: false, text: "Revision History", href: "RevisionHistory", id: "RevisionHistory" }
        ],
        headers: [
          { title: "PAY GRADE", value: "paygrade" },
          { title: "RANGE MINIMUM", value: "currentRangeMinimum" },
          { title: "RANGE MIDPOINT", value: "currentRangeMidPoint" },
          { title: "RANGE MAXIMUM", value: "currentRangeMaximum" },
          { title: "LAST REVISED DATE", value: "updatedAt" },
          { title: "REVISED BY", value: "revisedBy" },
          { title: "", value: "id", image: true },
        ],
      }
    },
    methods: {
      onGetQuery(queryParams){
        if(queryParams !== undefined){
          this.queryParams = {...this.queryParams, ...queryParams}
          this.getPayGradeRevisionHistory({
            paygradeId: this.historyId,
            queryParams: this.queryParams
          })
        } else {
          this.queryParams = {}
          this.getPayGradeRevisionHistory({
            paygradeId: this.historyId,
            queryParams: this.queryParams
          })
        }
      },
      async getPayGradeRevisionHistory(payload){
        this.isFetching = true
        await this.$_getPayGradeRevisionHistory(payload).then(({ data }) => {
          this.historys = data.history.map((item) => ({
            ...item, ...item.review, ...item.paygrade
          }))
          this.pagination = data.meta
          this.isFetching = false
        })
      },
      async bootstrapModule(){
        this.historyId = this.$route.params.id
        await this.getPayGradeRevisionHistory({
          paygradeId: this.historyId,
          queryParams: this.queryParams
        })
      }
    },
    async created(){
      this.isLoading = true
      try {
        await this.$handlePrivilege('payInsight', 'viewRevisionHistory')
        this.hasModuleAccess = true
        this.bootstrapModule()
        this.isLoading = false
      } catch (error) {
        this.hasModuleAccess = false
        this.isLoading = false
      }
    },
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-class {
   padding: 10px 40px !important;
   font-size: 16px !important;
   font-weight: 600 !important;
 }
</style>
